<template>
  <div>
    <!--begin::Documents-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Document Renew</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-2 mb-2 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToDocumentList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_document_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="document.name"
                :counter="20"
                :rules="nameRules"
                label="Document Name"
                required
                disabled
                dense
                outlined
                style="font-size: 13px;"
              ></v-text-field>

              <v-row align="center">
                <v-col cols="12" lg="6">
                  <v-menu :close-on-content-click="false" max-width="290">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedOldDateFormattedMomentjs"
                        clearable
                        class="date-text-input"
                        label="Old Renewal Date"
                        readonly
                        disabled
                        v-bind="attrs"
                        v-on="on"
                        dense
                        outlined
                        @click:clear="document.renewal_date = null"
                      ></v-text-field>
                    </template>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-menu :close-on-content-click="false" max-width="290">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormattedMomentjs"
                        clearable
                        label="Next Renewal Date"
                        readonly
                        class="date-text-input"
                        v-bind="attrs"
                        dense
                        outlined
                        v-on="on"
                        @click:clear="document.new_renewal_date = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="document.new_renewal_date"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row
                class="justify-end"
                style="margin-top: 10px !important;margin-bottom: 15px !important;"
              >
                <a @click="downloadDoc(document.id)" download>
                  Download Existing Document
                </a>
              </v-row>
              <v-row>
                <v-file-input
                  show-size
                  label="Document file"
                  ref="file"
                  dense
                  outlined
                  v-model="document.doc_file"
                >
                </v-file-input>
                <!-- <input type="file" class="custom-file-input" id="customFile" ref="file" @change="inputChanged()"> -->
              </v-row>

              <v-row class="card-footer pl-3 pt-3">
                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="validateRenewForm"
                >
                  <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Update
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="redirectToDocumentList()"
                >
                  Cancel
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::Documents-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import moment from "moment";
import { format, parseISO } from "date-fns";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

export default {
  name: "documents",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      document: {
        name: "",
        doc_file: [],
        new_renewal_date: format(
          parseISO(new Date().toISOString()),
          "yyyy-MM-dd"
        ),
        renewal_date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
        doc_files: "",
        doc_path: ""
      },
      disable_btn: false,
      menu1: false,
      fileRules: [
        value =>
          !value ||
          value.size < 2000000 ||
          "File size should be less than 2 MB!"
      ],
      valid: true,
      nameRules: [
        v => !!v || "Name is required",
        v => (v && v.length <= 20) || "Name must be less than 20 characters"
      ],
      edit_form: false
    };
  },
  props: {
    doc_id: String
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["companyUsers"]),

    computedDateFormattedMomentjs() {
      return this.document.new_renewal_date
        ? moment(this.document.new_renewal_date).format("MM-DD-YYYY")
        : "";
    },
    computedOldDateFormattedMomentjs() {
      return this.document.renewal_date
        ? moment(this.document.renewal_date).format("MM-DD-YYYY")
        : "";
    },
    icon() {
      if (this.likesAllUsers) return "mdi-close-box";
      if (this.likesSomeUser) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    config() {
      return this.layoutConfig();
    },
    likesAllUsers() {
      return (
        this.document.selected_users.length === this.company_user_list.length
      );
    },
    likesSomeUser() {
      return this.document.selected_users.length > 0 && !this.likesAllUsers;
    }
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "document/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          context.document.id = result.data.document.id;
          context.document.name = result.data.document.name;
          context.document.renewal_date = result.data.document.renewal_date;
          context.document.doc_path = result.data.document.doc_path;
        },
        function(error) {
          Swal.fire("Error", "Document not found.", "error");
          context.$router.push({ name: "documentsList" });
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Documents" }]);

    const document_form = KTUtil.getById("kt_document_form");

    this.fv = formValidation(document_form, {
      fields: {
        name: {
          validators: {
            notEmpty: {
              message: "Name is required"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    downloadDoc(doc_id) {
      let url =
        process.env.VUE_APP_DOC_URL +
        "api/downloadDocument/" +
        doc_id;
      window.open(url);
    },
    updateDocument() {
      var context = this;
      var formData = new FormData();
      formData.append("doc_file", this.document.doc_file);
      formData.append("name", this.document.name);
      formData.append("new_renewal_date", this.document.new_renewal_date);
      //put/patch request return blank array as form data.
      axios.defaults.baseURL = process.env.VUE_APP_ACBACK_URL;
      axios
        .post("document/" + this.$route.params.id + "/renew", formData)
        .then(response => {
          Swal.fire("Renew", response.data.message, "success");
        })
        .then(() => {
          this.disable_btn = false;
          context.$router.go(-1);
        },
        function(error) {
          Swal.fire("Error", "Document not found.", "error");
          context.$router.push({ name: "documentsList" });
        });
      this.disable_btn = false;
    },
    redirectToDocumentList() {
      var context = this;
      context.$router.go(-1);
      // this.$router.push({ name: "documentsList" });
    },
    validateRenewForm() {
      this.disable_btn = true;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        this.updateDocument();
        this.snackbar = true;
      } else {
        this.updateDocument();
      }
    },
    validate() {
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        this.submitDocument();
        this.snackbar = true;
      } else {
        this.submitDocument();
      }
    }
  }
};
</script>
